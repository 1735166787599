import { UserAuthCard } from "@ory/elements"
import classNames from "classnames"
import { useAnalytics } from "../../../hook/useAnalytics"
import { useRegistrationFlow } from "../../hooks/userFlows"

const AuthCardWrapper = () => {
  const flow = useRegistrationFlow()

  const track = useAnalytics({
    event: "track",
    action: "GenericSignUpClicked",
    track: {
      GenericPlacement: "home_auth_card",
      GenericVariant: "signup_page_main",
    },
  })

  if (!flow) {
    return null
  }

  return (
    <UserAuthCard
      flow={flow}
      flowType={"registration"}
      additionalProps={{
        loginURL: "https://console.ory.sh/login",
      }}
      onSubmit={() => {
        track()
      }}
      title={"Sign up for Ory"}
      includeScripts={true}
      className={classNames(
        "z-20 ml-auto mr-auto hidden lg:block xl:block 2xl:block",
        // This needs to override default UserAuthCard styles
        "!max-w-max",
      )}
    />
  )
}

export default AuthCardWrapper
